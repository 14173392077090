<template>
  <div class="sub-page-listing-content" v-if="pages.length" ref="subPageListingContent">
    <ul>
      <li v-for="page in pages" :key="page.id">
        <a :href="`${page.url}`">{{ page.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SubPageListingComponent',
  props: {
    pages: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="scss">
.sub-page-listing-content {
  ul {
    list-style-type: none;
    padding-left: 10px;

    &:first-child {
      border-left: 4px solid $primary;
    }

    ul {
      margin-left: 20px;
      padding-left: 0;
    }

    li {
      a {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1200px) {
    position: static;
    left: auto;
    right: 10px;
    max-width: 100%;
    padding: 0;
  }

  @media (max-width: 768px) {
    position: static;
    max-width: 100%;
    padding: 0;
  }
}
</style>
